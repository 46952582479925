import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { RootLayout } from '../root/root.component';
import * as $ from 'jquery';

@Component({
  selector: 'simplywhite-layout',
  templateUrl: './simplywhite.component.html',
  styleUrls: ['./simplywhite.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SimplyWhiteLayout extends RootLayout implements OnInit {

  errors:boolean = false;

  menuLinks = [
    {
      label: "Dashboard",
      routerLink: "/dashboard",
      roles:[1,2],
      iconType: "fal",
      iconName: "fal fa-dashboard",
    },
    {
      label: "Patients",
      routerLink: "/patient",
      roles:[1,2],
      iconType: "fal",
      iconName: "fal fa-hospital-user",
    },
    {
      label: "Users",
      routerLink: "/user",
      roles:[1,2],
      iconType: "fal",
      iconName: "fal fa-user-friends",
    },
    {
      label: "Settings",
      routerLink: "/settings",
      roles:[1,2],
      iconType: "fal",
      iconName: "fal fa-cogs",
    },
    {
      label: "Notifications",
      routerLink: "/notification",
      roles:[1,2],
      iconType: "fal",
      iconName: "fal fa-bell",
    },
    {
      label: "Agenda",
      routerLink: "/agenda",
      roles:[1,2],
      iconType: "fal",
      iconName: "fal fa-calendar-check",
    }
  ];
  ngOnInit() {
    this.changeLayout("menu-pin");
    //Will sidebar close on screens below 1024
    this.autoHideMenuPin();
    document.documentElement.style.setProperty('--primary-color', '#546E87');
    document.documentElement.style.setProperty('--secondary-color', '#7DA1B5');
    this.filterMenu(this.authService);
  }

  isAuthenticated(){
    return this.authService.authenticated;
  }

  timeout:any;

  isDashboard(){
    if(this.router.url.includes("dashboard")){
      return true;
    }
  }

  sidebar(isLeaving){
    if(this.timeout){
      clearTimeout(this.timeout);
    }
    if(!isLeaving){
      $('pg-sidebar').addClass('showing-sidebar');
    }
    else{
      this.timeout = setTimeout(() => {
        $('pg-sidebar').removeClass('showing-sidebar');
      }, 500);
    }
  }

  getLogo(){
    return this.authService.getCompanyLogo();
  }

  signout(){
    this.authService.signout();
  }

  isSystemAdmin() : boolean{
    if(this.authService.getUserRole() == 1){
      return true;
    }
    return false;
  }

  getFullName(){
    if(!this.authService.user){
      return "";
    }
    return this.authService.user.name;
  }

  filterMenu(authService){
    let role = authService.getUserRole();
    for(let parent = 0; parent < this.menuLinks.length;parent++){
        if(!this.menuLinks[parent].roles.includes(role)){
            this.menuLinks[parent] = null;
        }
    }
    this.menuLinks = this.menuLinks.filter(Boolean);
}

}
