import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxEchartsModule } from 'ngx-echarts';
import { NvD3Module } from 'ngx-nvd3';
import { pgDatePickerModule } from '../../@pages/components/datepicker/datepicker.module';
import { NotificationsComponent } from './notifications.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressModule } from '../../@pages/components/progress/progress.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    NgxEchartsModule,
    NvD3Module,
    pgDatePickerModule,
    ModalModule,
    ProgressModule
  ],
  declarations:[NotificationsComponent],
  exports: [NotificationsComponent],
  //providers:[MessageService]
})
export class NotificationModule { }
