import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NotificationService } from '../../shared/services/notification.service';
import { Observable, Subject } from 'rxjs';
import { WebsocketService } from '../../shared/services/websocket.service';
import { LocalStoreService } from '../../shared/services/local-store.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {

  visible: boolean = true;
  notificationList: any[] = [];
  @Input() forceLoad:boolean = false;
  @Output() outputData:EventEmitter<any> = new EventEmitter<any>();
  @Input() formData:FormGroup;
  
  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private wsService: WebsocketService,
    private localStore : LocalStoreService,
    private fb : FormBuilder
  ) { }

  ngOnInit() {
    this.loadNotifications();
    this.startSocket();
    let _this = this;
    setInterval(() => {
      if(_this.forceLoad){
        _this.forceLoad = false;
        _this.loadNotifications();
      }
    },1000);
  }

  submitRemark(event){
    console.log(event);
}

  ngOnDestroy(){
    if(this.messages){
      this.messages.unsubscribe();
    }
  }

  isTouched(controlName) {
    if (this.formData.get(controlName).touched && this.formData.get(controlName).invalid) {
      return true;
    }
    else {
      return false;
    }
  }

  loading:boolean = false;
  loadNotifications() {
    let _this = this;
    _this.loading = true;
    this.notificationService.getAll({}).subscribe(res => {
      _this.notificationList = res['data'];
      _this.loading = false;
    },
      err => {
        console.log(err);
        _this.loading = false;
      })
  }

  activeNotification:any;
  readNotification(notification) {
    this.activeNotification = notification;
    this.outputData.emit({showModal:true,notification:notification});
    console.log(this.activeNotification);
  }

  isMatch(notification){
    if(!this.activeNotification){
      return false;
    }
    if(notification.id == this.activeNotification.id){
      return true;
    }
    return false;
  }

  viewNotification(notification) {
    this.activeNotification = notification;
    this.outputData.emit(notification);
  }

  messages: Subject<any>;
  startSocket() {
    let __this = this;
    try {
      this.messages = <Subject<any>>this.wsService.connect().map((response: any): any => {
        return response;
      });
      this.messages.subscribe(res => {
        __this.handleSocket(res);
      },
        err => {
          console.log(err);
        })


    }
    catch (err) {

    }
  }

  handleSocket(res) {
    switch (res['socket_name']) {
      case 'egv-alerts':
        //console.log("Received Notification: ",res);
        for(let incomingNotification of res['0']){
          this.notificationList = [incomingNotification,...this.notificationList];
          //console.log(incomingNotification);
        }
        //this.notificationList.push(res);    
        break;
    }

  }

}
