import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PatientService {

    constructor(
        private http: HttpClient
    ) { }

    getAll(searchParams) {
        return this.http.get(`${environment.baseUrl}/patient`, { params: searchParams });
    }

    get(id) {
        return this.http.get(`${environment.baseUrl}/patient/` + id);
    }

    getEGV(id,searchParams) {
        return this.http.get(`${environment.baseUrl}/patient/` + id+`/egv`, { params: searchParams });
    }

    getEvents(id,searchParams) {
        return this.http.get(`${environment.baseUrl}/patient/` + id+`/event`, { params: searchParams });
    }

    edit(id, formData) {
        return this.http.put(`${environment.baseUrl}/patient/` + id, formData);
    }

    create(formData) {
        return this.http.post(`${environment.baseUrl}/patient`, formData);
    }

    delete(id) {
        return this.http.delete(`${environment.baseUrl}/patient/` + id);
    }

    sendEmailInvite(formData){
        return this.http.post(`${environment.baseUrl}/mail/invitation`,formData);
    }

    getNotes(id,params){
        return this.http.get(`${environment.baseUrl}/patient/`+id+'/note',{params:params});
    }

    createNote(id,formData){
        return this.http.post(`${environment.baseUrl}/patient/`+id+'/note',formData);
    }

    uploadEGV(id,data){
        return this.http.post(`${environment.baseUrl}/patient/`+id+'/egv/import',{data:data});
    }

    bulkUploadEGV(data){
        return this.http.post<any>(`${environment.baseUrl}/patient/egv/import/bulk`,data);
    }

}
