import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxEchartsModule } from 'ngx-echarts';
import { NvD3Module } from 'ngx-nvd3';
import { pgDatePickerModule } from '../../@pages/components/datepicker/datepicker.module';
import { NotesComponent } from './notes.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressModule } from '../../@pages/components/progress/progress.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    NgxEchartsModule,
    NvD3Module,
    pgDatePickerModule,
    ModalModule,
    ProgressModule,
    NgxDatatableModule,
  ],
  declarations:[NotesComponent],
  exports: [NotesComponent],
  //providers:[MessageService]
})
export class NotesModule { }
