import { Injectable } from '@angular/core';
import { Roles, RoleAccess } from '../models/roles.model';

@Injectable()
export class RoleService {
  public roles: Roles = {
    1: {
      role: 'Admin',
      defaultRoute: '/dashboard',
      routeAccess: {
        user:true,
        profile: true,
        dashboard: true,
        patient:true,
        settings:true,
        notification:true,
        agenda: true,
        ['dashboard-doctor']:true
      }
    },
    2: {
      role: 'Doctor',
      defaultRoute: '/dashboard',
      routeAccess: {
        user:true,
        profile: true,
        dashboard: true,
        patient:false,
        settings:false,
        notification:false,
        agenda: true,
        ['dashboard-doctor']:true
      }
    }
    

  };

  constructor() { }

  getAccess(role: number): RoleAccess {
    return <RoleAccess>this.roles[role];
  }
}
