import { Routes } from '@angular/router';
//Layouts
import { 
  SimplyWhiteLayout,
  BlankSimplywhiteComponent
} from './@pages/layouts';
import { AuthGaurd } from './shared/guards/auth-guard.service';
import { RoleGuard } from './shared/guards/role-guard';


export const AppRoutes: Routes = [

  {
    path: '',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout
  },
  {
    path: 'dashboard-doctor',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/dashboard-doctor/dashboard.module#DashboardDoctorModule"
  },
  {
    path: 'dashboard',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/dashboard/dashboard.module#DashboardModule"
  },
  
  {
    path: 'user',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/user/user.module#UserModule"
  },
  {
    path: 'patient',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/patient/patient.module#PatientModule"
  },
  {
    path: 'session',
    data: {
        breadcrumb: 'Home'
    },
    component: BlankSimplywhiteComponent,
    loadChildren: "./view/session/session.module#SessionModule"
  },
  {
    path: 'profile',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/profile/profile.module#ProfileModule"
  },
  {
    path: 'settings',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/settings/settings.module#SettingsModule"
  },
  {
    path: 'notification',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/notification-list/notification.module#NotificationModule"
  },
  {
    path: 'agenda',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/agenda/agenda.module#AgendaModule"
  },
];
