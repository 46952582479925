import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from '../../../environments/environment';
import { Page } from '../../shared/models/page.model';
import { PatientService } from '../../shared/services/patient.service';
import * as moment from 'moment';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  visible: boolean = true;
  noteList: any[] = [];
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  @ViewChild('addNote', { static: true }) mdSlideUp: ModalDirective;
  freeSearch: string;
  ColumnMode = ColumnMode;
  page: Page = new Page();
  currentPage = 1;
  columns = [];
  rows = [];
  searchParams = { per_page: environment.per_page, page: this.currentPage };
  @Input() patientId:number;
  @Input() onValueChanged:EventEmitter<any>;
  noteForm:FormGroup;
  
  constructor(
    private patientService : PatientService,
    private fb : FormBuilder
  ) { }

  ngOnInit() {
    let _this = this;
    this.createForm({});
    this.loadNotes();
    if(this.onValueChanged){
      this.onValueChanged.subscribe(res => {
        setTimeout(() => {
          _this.loadNotes();
        }, 500);
      })
    }
    this.columns = [
      { name: 'Note', flexGrow: 1, sortable: true, cellClass: 'note_note', headerClass: 'note_note' },
      { name: 'Publish Date', flexGrow: 1, sortable: true, cellClass: 'note_date', headerClass: 'note_date' },
      { name: 'Published By', flexGrow: 1, sortable: true, cellClass: 'note_by', headerClass: 'note_by' }
    ];
  }

  saveNote(){
    let _this = this;
    if(this.noteForm.invalid){
      this.noteForm.markAllAsTouched();
      return;
    }
    this.patientService.createNote(this.patientId,this.noteForm.getRawValue()).subscribe(res => {
      _this.noteForm.reset();
      _this.loadNotes();
      _this.mdSlideUp.hide();
    },
    err => {
      console.log(err);
    });
  }

  loading:boolean = false;


  createForm(input?){
    this.noteForm = this.fb.group({
      note:[input.note,Validators.required]
    });
  }

  isTouched(controlName) {
    if (this.noteForm.get(controlName).touched && this.noteForm.get(controlName).invalid) {
      return true;
    }
    else {
      return false;
    }
  }

  onSort(event) {
    this.searchParams['order_by'] = event['column']['prop'];
    this.searchParams['order_asc_desc'] = event['newValue'];
    this.loadNotes();
  }

  loadNotes() {
    let _this = this;
    _this.noteList = [];
    _this.loading = true;
    this.patientService.getNotes(this.patientId,this.searchParams).subscribe(res => {
      _this.page = res['meta'];
      _this.noteList = res['data'];
      _this.rows = [];
      //console.log(res['data']['data'])
      for (let note of _this.noteList) {
        _this.rows.push(
          {
            id: note.id,
            note: note.note,
            publishDate:  moment( note.created_at ).format('DD/MM/YYYY HH:mm'),
            publishedBy: note.created_by.name
          }
        );
      }
    });
  }

  setPage(pageInfo) {
    this.currentPage = pageInfo.offset + 1;
    //console.log(pageInfo);
    this.searchParams.page = this.currentPage;
    //console.log(this.currentPage);
    this.loadNotes();
  }

  ngAfterViewInit() {
    this.table.bodyComponent.updatePage = function (direction: string): void {
      let offset = this.indexes.first / this.pageSize;

      if (direction === 'up') {
        offset = Math.ceil(offset);
      } else if (direction === 'down') {
        offset = Math.floor(offset);
      }

      if (direction !== undefined && !isNaN(offset)) {
        this.page.emit({ offset });
      }
    }
  }

  updateFilters() {
    this.searchParams = { per_page: environment.per_page, page: 1 };
    if (this.freeSearch) {
      this.searchParams['q'] = this.freeSearch;
    }
    this.loadNotes();
  }


}
