import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.baseUrl}/alertnotification`,{params:searchParams});
    }

    get(id){
      return this.http.get(`${environment.baseUrl}/alertnotification/`+id);
    }

    edit(id,formData){
      return this.http.put(`${environment.baseUrl}/alertnotification/`+id,formData);
    }

    create(formData){
      return this.http.post(`${environment.baseUrl}/alertnotification`,formData);
    }

    read(formData,id){
      return this.http.post(`${environment.baseUrl}/alertnotification/`+id+`/read`,formData);
    }

    delete(id){
      return this.http.delete(`${environment.baseUrl}/alertnotification/`+id);
    }


}
